
/* You can add global styles to this file, and also import other style files */

:root {
  // color variables

  // typography variables

  // Spacing variables (used for margin, padding, gaps, position values etc)
  --emfe-w-spacing-3x-small: 0.125rem; /* 2px */
  --emfe-w-spacing-2x-small: 0.25rem; /* 4px */
  --emfe-w-spacing-x-small: 0.5rem; /* 8px */
  --emfe-w-spacing-small-minus: 0.625rem; /* 10px */
  --emfe-w-spacing-small: 0.75rem; /* 12px */
  --emfe-w-spacing-medium: 1rem; /* 16px */
  --emfe-w-spacing-large: 1.25rem; /* 20px */
  --emfe-w-spacing-x-large: 1.75rem; /* 28px */
  --emfe-w-spacing-2x-large: 2.25rem; /* 36px */
  --emfe-w-spacing-3x-large: 3rem; /* 48px */
  --emfe-w-spacing-4x-large: 4.5rem; /* 72px */

  --emfe-w-border-radius-small: 0.1875rem; /* 3px */
  --emfe-w-border-radius-medium: 0.25rem; /* 4px */
  --emfe-w-border-radius-large: 0.5rem; /* 8px */

  // Custom size variables (used for width, height etc)
  --emfe-w-size-x-small: 0.625rem; /* 10px */
  --emfe-w-size-small: 0.875rem; /* 14px */
  --emfe-w-size-small-plus: 0.9375rem; /* 15px */
  --emfe-w-size-standard: 1rem; /* 16px */
  --emfe-w-size-medium-4x-minus: 1.25rem; /* 20px */
  --emfe-w-size-medium-3x-minus: 1.875rem; /* 30px */
  --emfe-w-size-medium-2x-minus: 2.5rem; /* 40px */
  --emfe-w-size-medium-1x-minus: 3.125rem; /* 50px */
  --emfe-w-size-medium: 5.25rem; /* 84px */
  --emfe-w-size-medium-plus: 6.25rem; /* 100px */
  --emfe-w-size-2x-medium: 9.375rem; /* 150px */
  --emfe-w-size-3x-medium: 18.75rem; /* 300px */
  --emfe-w-size-4x-medium: 37.5rem; /* 600px */
  --emfe-w-size-4x-large: 81.25rem; /* 1300px */

  // Transition variables
  --emfe-w-transition-x-slow: 1000ms;
  --emfe-w-transition-slow: 500ms;
  --emfe-w-transition-medium: 250ms;
  --emfe-w-transition-fast: 150ms;
  --emfe-w-transition-x-fast: 50ms;

  // Font size variables
  --emfe-w-font-size-2x-small: 0.625rem; /* 10px */
  --emfe-w-font-size-x-small: 0.75rem; /* 12px */
  --emfe-w-font-size-small: 0.875rem; /* 14px */
  --emfe-w-font-size-small-plus: 0.9375rem; /* 15px */
  --emfe-w-font-size-medium: 1rem; /* 16px */
  --emfe-w-font-size-large: 1.25rem; /* 20px */
  --emfe-w-font-size-x-large: 1.5rem; /* 24px */
  --emfe-w-font-size-2x-large: 2.25rem; /* 36px */
  --emfe-w-font-size-3x-large: 3rem; /* 48px */
  --emfe-w-font-size-4x-large: 4.5rem; /* 72px */

  /* Font weight variables */
  --emfe-w-font-weight-light: 300;
  --emfe-w-font-weight-normal: 400;
  --emfe-w-font-weight-semibold: 500;
  --emfe-w-font-weight-bold: 700;

  /* Z-index variables */
  --emfe-w-index-drawer: 700;
  --emfe-w-index-dialog: 800;
  --emfe-w-index-dropdown: 900;
  --emfe-w-index-toast: 950;
  --emfe-w-index-tooltip: 1000;

  // ======================================================================================
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Theme tokens start ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // These are Design tokens, meant to be generic and highly reusable in all our components
  --emfe-w-color-white: #FFFFFF;
  --emfe-w-color-black: #000000;
  --emfe-w-color-gray-50: #F9F8F8;
  --emfe-w-color-gray-100: #E6E6E6;
  --emfe-w-color-gray-150: #828282;
  --emfe-w-color-gray-300: #58586B;
  --emfe-w-color-gray-600: #1a1a1a;

  --emfe-w-color-gray-transparency-20: rgba(255, 255, 255, .2);
  --emfe-w-color-gray-transparency-30: rgba(255, 255, 255, .3);
  --emfe-w-color-gray-transparency-40: rgba(255, 255, 255, .4);
  --emfe-w-color-gray-transparency-60: rgba(255, 255, 255, .6);
  --emfe-w-color-gray-transparency-80: rgba(255, 255, 255, .8);
  --emfe-w-color-gray-transparency-100: rgba(255, 255, 255, 1);

  --emfe-w-color-pale-orange: #ffe9dc;

  --emfe-w-color-orange-50: #F57E32; // variation of primary color
  --emfe-w-color-orange-100: #F57E32; // variation of primary color
  --emfe-w-color-orange-200: #e0691f; // variation of primary color
  --emfe-w-color-orange-500: #FD2839; // primary color
  --emfe-w-color-orange-600: #a14b15; // variation of primary color

  --emfe-w-color-dunkel: #07072A;
  --emfe-w-color-dunkel-600: #0D0D4D;
  --emfe-w-color-dark-blue: #020202;
  --emfe-w-color-darkest-blue: #050518;

  --emfe-w-color-red: #FD2839;
  --emfe-w-color-green: #48952a;

  // Changes the primary theme color using primitives - these are the Theme tokens
  --emfe-w-color-primary-20: var(--emfe-w-color-orange-50);
  --emfe-w-color-primary-50: var(--emfe-w-color-orange-100);
  --emfe-w-color-primary-500: var(--emfe-w-color-orange-200);
  --emfe-w-color-primary-600: var( --emfe-w-color-orange-600);
  --emfe-w-color-primary: var(--emfe-w-color-orange-100);  // primary

  --emfe-w-color-secondary-50: var(--emfe-w-color-orange-50);
  --emfe-w-color-secondary-100: var(--emfe-w-color-orange-100);
  --emfe-w-color-secondary-200: var(--emfe-w-color-orange-200);
  --emfe-w-color-secondary: var(--emfe-w-color-orange-50); // secondary

  --emfe-w-color-contrast: var(--emfe-w-color-gray-600);
  --emfe-w-color-contrast-600:  var(--emfe-w-color-gray-600);

  --emfe-w-color-error: var(--emfe-w-color-red);
  --emfe-w-color-valid: var(--emfe-w-color-green);

  --emfe-w-color-pale: var(--emfe-w-color-pale-orange);

  // all down way not used
  // --emfe-w-color-background-transparent-20: var(--emfe-w-color-gray-transparency-20);
  // --emfe-w-color-background-transparent-30: var(--emfe-w-color-gray-transparency-30);
  // --emfe-w-color-background-transparent-40: var(--emfe-w-color-gray-transparency-40);
  // --emfe-w-color-background-transparent-60: var(--emfe-w-color-gray-transparency-60);
  // --emfe-w-color-background-transparent-80: var(--emfe-w-color-gray-transparency-80);
  // --emfe-w-color-background-transparent-100: var(--emfe-w-color-gray-transparency-100);

  // BUTTON's text/svg color
  --emfe-w-button-typography: var(--emfe-w-color-white);

  // HEADER
  --emfe-w-header-color-menu-bg: var(--emfe-w-color-darkest-blue);
  --emfe-w-header-color-secondary-menu-bg: var(--emfe-w-color-darkest-blue);
  --emfe-w-header-color-primary: var(  --emfe-w-color-primary-50);
  --emfe-w-header-contrast: var(--emfe-w-color-dunkel);
  --emfe-w-header-typography: var(--emfe-w-color-white);

  // CATEGORIES BAR
  --emfe-w-categories-color-primary: var(  --emfe-w-color-primary-50);
  --emfe-w-categories-color-secondary: var(  --emfe-w-color-primary-50);
  --emfe-w-categories-contrast: var(--emfe-w-color-gray-600);
  --emfe-w-categories-arrows: var( --emfe-w-color-white);
  --emfe-w-categories-arrows-bg: var(--emfe-w-color-gray-transparency-30);
  --emfe-w-categories-typography: var(--emfe-w-color-white);

  // CASINO
  --emfe-w-casino-color-bg: var(--emfe-w-color-gray-600);
  --emfe-w-casino-color-primary: var(  --emfe-w-color-primary-50);
  --emfe-w-casino-color-secondary: var(  --emfe-w-color-primary-50); // not used yet
  --emfe-w-casino-contrast: var(--emfe-w-color-gray-600);
  --emfe-w-casino-typography: var(--emfe-w-color-white);

  // TOURNAMENTS -- not used
  --emfe-w-tournamnets-color-bg: var(--emfe-w-color-darkest-blue);
  --emfe-w-tournamnets-color-primary: var(--emfe-w-color-primary-50);
  --emfe-w-tournamnets-contrast: var(--emfe-w-color-dunkel);
  --emfe-w-tournamnets-typography: var(--emfe-w-color-white);

  // PAM
  --emfe-w-pam-color-bg: var(--emfe-w-color-gray-50);
  --emfe-w-pam-color-nav-desktop-bg: var(--emfe-w-color-gray-300);
  --emfe-w-pam-color-nav-mobile-bg: var(--emfe-w-color-gray-600);
  --emfe-w-pam-typography-color-nav-bg: var(--emfe-w-color-white);
  --emfe-w-pam-color-primary: var(--emfe-w-color-primary-50);
  --emfe-w-pam-contrast: var(--emfe-w-color-dunkel);
  --emfe-w-pam-typography: var(--emfe-w-color-dunkel);

  // LOGIN / REGISTER
  --emfe-w-registration-color-bg: var(--emfe-w-color-gray-50);
  --emfe-w-registration-color-modal-bg: var(--emfe-w-color-white);
  --emfe-w-registration-color-primary: var(--emfe-w-color-primary-50);
  --emfe-w-registration-contrast: var(--emfe-w-color-gray-300);
  --emfe-w-registration-typography: var(--emfe-w-color-dunkel);
  --emfe-w-registration-title-typography: var(--emfe-w-color-black);

  // FOOTER
  --emfe-w-footer-color-bg: var(--emfe-w-color-dark-blue);
  --emfe-w-footer-color-primary: var(--emfe-w-color-pink-500); // not used yet
  --emfe-w-footer-contrast: var(--emfe-w-color-dunkel); // not used yet
  --emfe-w-footer-typography: var(--emfe-w-color-white);

// MISC (403, 404, 500 …)
  --emfe-w-misc-color-bg: var(--emfe-w-color-contrast);
  --emfe-w-misc-color-primary: var(--emfe-w-color-primary-50);
  --emfe-w-misc-contrast: var(  --emfe-w-color-gray-600); // not used
  --emfe-w-misc-typography: var(--emfe-w-color-white);

// ======================================================================================
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Theme tokens END ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

}

$header-height: 145px;
$header-height-mobile: 55px;

body {
  margin: 0;
  font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  overflow-x: hidden;
  touch-action: pan-y;
  
  &.scroll-behind-off{
    overflow-y: hidden;
  }

  &.ScrollBehindOffIOS{
    overflow-y: hidden;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
}

.grecaptcha-badge {
  display: none;
}

.PageWrapperContent {
  color: var(--emfe-w-misc-typography, var(--emfe-w-color-white, #FFFFFF));
  background: var(--emfe-w-misc-color-bg, var(--emfe-w-color-dunkel, #07072A));
}

.PageWrapperContentSpacing {
  padding-top: $header-height;
  background-color: #07072A;
  &Mobile {
    padding-top: $header-height-mobile;
  }
}
